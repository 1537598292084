<template>
  <div id="app" v-cloak>
    <router-view></router-view>
    <Login></Login>
  </div>
</template>

<script>
import Login from '@/components/login.vue'
export default {
  name: 'app',
  components: {
        Login
  },
      data(){
        return{

        }
      }
}
</script>

<style lang="less">
  [v-cloak] {
    display: none !important;

  }
  @import "assets/base_qdd.css";
  body{
    display: block;
    background: #F5F5F5;
  }
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;

  }
  .header{
    position: fixed;
    top: 0;
    left: 0;
    background: #F7F7F7;
    width: 100%;
    height: 32px;
    line-height: 32px;
    z-index: 9999;
    .headcon{
      width:1200px;
      height: 32px;
      margin: 0 auto;
      font-size: 14px;
      color: #999999;

      button{
        float: right;
        background: none;
        outline: none;
        border: none;
        font-size: 14px;
        line-height: 32px;
        color: #999999;
        margin-left: 20px;
        cursor: pointer;
      }
      span{
        float: left;
      }
      p{
        float: right;
        color: #999;
        cursor: pointer;
      }
      p:hover,button:hover{
        color: #EC1430;
      }
    }


  }


  .el-pagination.is-background .el-pager li:not(.disabled).active{
  background: #EC1430 !important;
  }
  .el-pagination.is-background .el-pager li:not(.disabled):hover{
    color: #EC1430 !important;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active:hover{
    color: #fff !important;
  }
  .el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li{
    background: #fff !important;
  }
</style>
