import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
      return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('@/components/headTop'),    // top顶栏
    redirect:'/index',
    children:[
      {
        path: '/slideBar',
        name: 'slideBar',
        component: () => import('@/components/slideBar'),    //右边功能栏
      },
      {
        path: '/index',
        name: 'index',
        component: () => import('@/views/homePage/index'),    //默认首页
      },{
        path: '/bannerCon',
        name: 'bannerCon',
        component: () => import('@/views/homePage/bannerCon'),    //轮播
      },
      {
            path: '/newGoods',
            name: 'newGoods',
            component: () => import('@/views/homePage/newGoods'),    //首页-新品首发
        },
        {
        path: '/footer', // 底部
        name: 'footer',
        component: () => import('@/components/footer'),    // 底部
      },
      {
          path: '/goodsList', // 专区商品列表
          name: 'goodsList',
          component: () => import('@/views/filtrateList/filtrateList'),
      },
      {
            path:'/scarchList',
            name: 'scarchList',
            component: () => import('@/views/filtrateList/scarchList'),
      },
      {
          path: '/brand', // 品牌
          name: 'brand',
          component: () => import('@/views/brand/brand.vue')
       },
       {
            path:'/brandList',
            name: 'brandList',
            component: () => import('@/views/brand/brandList.vue')
       },
      {
          path: '/details',// 商品详情
          name: 'details',
          component: () => import('@/views/details/details')
       },
        {
            path: '/login', // 登录
            name: 'login',
            component: () => import('@/components/login.vue')
        },
    ]
  },
    {
        path: '/aboutUs', // 关于我们
        name: 'aboutUs',
        component: () => import('@/views/aboutUs/contact')
    },


]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
