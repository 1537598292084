import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)


const store = new Vuex.Store({
      state:{
            isShowLogin:0,//是否显示登陆框；
      },

      mutations:{
            changeShowLogin(state,newState){
                  state.isShowLogin = newState;
            }
      }
})

export default store