<!--
		icon组件
    接收父组件的参数有：
    name：使用class形式，size：尺寸 rem，color：颜色
 -->
<template>
  <i :class="'x-icon iconfont ' + name" :style="styles"></i>
</template>

<script>
export default {
	props: {
		name: {
			type: String,
			default: ''
		},
		size: {
			type: String,
			default: ''
		},
		color: {
			type: [String, Array],
			default: ''
		}
	},
  name: 'iconfont',
  data () {
    return {

    }
  },
  methods: {
		isEmpty (val) {
				if (val === undefined || val === null || val === '') {
						return true
				} else {
						return false
				}
		}
	},
  computed: {
		styles () {
			let style = ''
			if (!this.isEmpty(this.size)) {
				style += `font-size: ${this.size};`
			}
			if (!this.isEmpty(this.color)) {
				style += `color: ${this.color};`
			}
			return style
		}
	}
}

</script>
<style scoped>
  @import "//at.alicdn.com/t/font_1089236_7kqe594uge.css";

.x-icon{
	font-size: 20px;
}
</style>





