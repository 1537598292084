import { axios } from "./axios";

import { plus, privateKeyConte } from "./encryption.js";
export function portalLogin(parameter) {
    console.log(plus);
    // parameter = plus(parameter);
    console.log(parameter);
    parameter.time = Date.parse(new Date());
    return false
    return axios({
        url: "/auth/protal/portalLogin",
        method: "POST",
        data: parameter,
        headers: {
            "Content-Type": "application/json;charset=UTF-8",
        },
    });
}


export function GLdegioosstt(parameter) {
    console.log(plus);
    parameter = plus(parameter);
    console.log(parameter);
    parameter.time = Date.parse(new Date());
    return axios({
        url: "/goods/qddGoods/getGoodsList",
        method: "POST",
        data: parameter,
        headers: {
            "Content-Type": "application/json;charset=UTF-8",
        },
    });
}
export function getBrandGoods(parameter) {
    console.log(plus);
    parameter = plus(parameter);
    console.log(parameter);
    parameter.time = Date.parse(new Date());
    return axios({
        url: "/goods/qddGoodsCategory/category",
        method: "POST",
        data: parameter,
        headers: {
            "Content-Type": "application/json;charset=UTF-8",
        },
    });
}

export function getBrandGoodsbrand(parameter) {
    console.log(plus);
    parameter = plus(parameter);
    console.log(parameter);
    parameter.time = Date.parse(new Date());
    return axios({
        url: "/goods/qddGoods/getBrandGoods",
        method: "POST",
        data: parameter,
        params: parameter,
        headers: {
            "Content-Type": "application/json;charset=UTF-8",
        },
    });
}

export function getSign(parameter) {
    console.log(plus);
    parameter = plus(parameter);
    console.log(parameter);
    parameter.time = Date.parse(new Date());
    return axios({
        url: "/goods/qddGoods/getGoodsAndSub",
        method: "POST",
        data: parameter,
        params: parameter,
        headers: {
            "Content-Type": "application/json;charset=UTF-8",
        },
    });
}

export function listSign(parameter) {
    console.log(plus);
    parameter = plus(parameter);
    console.log(parameter);
    parameter.time = Date.parse(new Date());
    return axios({
        url: "/goods/sku/listSign",
        method: "POST",
        data: parameter,
        params: parameter,
        headers: {
            "Content-Type": "application/json;charset=UTF-8",
        },
    });
}

export function getPcFreight(parameter) {
    console.log(plus);
    parameter = plus(parameter);
    console.log(parameter);
    parameter.time = Date.parse(new Date());
    return axios({
        url: "/goods/qddGoods/getPcFreight",
        method: "POST",
        data: parameter,
        params: parameter,
        headers: {
            "Content-Type": "application/json;charset=UTF-8",
        },
    });
}

//首页banner
export function getBanner(parameter) {
    console.log(parameter);
    parameter = plus(parameter);
    console.log(parameter);
    parameter.time = Date.parse(new Date());
    return axios({
        url: "/goods/qddGoods/getBanner",
        method: "POST",
        data: parameter,
        params: parameter,
        headers: {
            "Content-Type": "application/json;charset=UTF-8",
        },
    });
}

//首页banner
export function protalLogin(parameter) {
    console.log(parameter);
    parameter = plus(parameter);
    console.log(parameter);
    parameter.time = Date.parse(new Date());
    return axios({
        url: "/auth/protal/pcLogin",
        method: "POST",
        data: parameter,
        params: parameter,
        headers: {
            "Content-Type": "application/json;charset=UTF-8",
        },
    });
}