import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

// 全局图片路径
Vue.prototype.globalImg = 'http://statics.76sd.com/static/dmyp_api/image/' //正式
Vue.prototype.imgPath = 'https://7dd-statics.oss-cn-beijing.aliyuncs.com' //正式

import { Pagination,Select,Option,Cascader } from 'element-ui';
import VueAwesomeSwiper from 'vue-awesome-swiper'
// require styles
import 'swiper/dist/css/swiper.css'


import 'element-ui/lib/theme-chalk/index.css';

import store from './store'

Vue.use(VueAwesomeSwiper /* { default global options } */ )
Vue.use(Select/* { default global options } */ )
Vue.use(Pagination/* { default global options } */ )
Vue.use(Cascader/* { default global options } */ )




new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')