<template>
    <div>
        <!--        登录弹框 begin -->
        <div class="login_bg" v-show="$store.state.isShowLogin">
            <div class="login_box">
                <ul>
                    <li>
                        <icon name="iconwodedangxuan" size="25px" color="#ddd" class="btn"></icon>
                        <input type="text" placeholder="输入用户名" value="" v-model="loginInfo.username"/>
                    </li>
                    <li>
                        <icon name="iconlock" size="25px" color="#ddd" class="btn"></icon>
                        <input type="password" placeholder="输入密码" value="" v-model="loginInfo.password"/>
                    </li>
                </ul>
                <button class="fl" @click="loginCancle">取消</button>
                <button class="fr red" @click="login">确定</button>
            </div>
        </div>

        <!--   登录弹框 end -->
        <!-- 提示 -->
        <toast ref="toast"></toast>
    </div>
</template>
<script>
    import icon from '@/components/icon/iconfont.vue'
    import toast from "@/components/toast.vue"
    import {login} from '@/views/homePage/home' // 获取登录
    import {protalLogin} from '@/global/https'

    
    export default {
        components:{
            icon,
            toast
        },
        data(){
            return{
                isShowPop:0, // 是否显示登录弹框
                // 登录
                loginInfo:{
                    username:'', // 用户名
                    password:'' // 密码
                }
            }
        },
        methods:{
            // 登录接口
            async login() {
                let params = this.loginInfo;
                if (params.username == "") {
                    this.$refs.toast.showToast("请输入账号~~");
                } else if (params.password == "") {
                    this.$refs.toast.showToast("请输入密码~~");
                } else {
                  
                    let {code,mas,token,userId,userName,agent_id,phone_mob}= await protalLogin(params);
                    if (code == 0) {
                        // 设置cookie

                        let cookie = localStorage.getItem("cookie") || {};
                        // cookie = JSON.parse(decodeURIComponent(decodeURIComponent(data.psw)));
                        localStorage.setItem("cookie", JSON.stringify({phone_mob:phone_mob,tokenId:token,user_id:userId,user_name:userName,agent_id:agent_id,}));
                        this.loginInfo.user_name = ''
                        this.loginInfo.password = ''
                        // 登录状态改为true
                        this.$refs.toast.showToast("登录成功");
                        // 隐藏弹框
                        this.isShowPop = false;
                          this.$store.commit('changeShowLogin',0)
                        location.reload();
                    } else {
                        this.$refs.toast.showToast(msg);
                    }
                }

            },
            // 是否显示弹框
            loginCancle(){
                this.$store.commit('changeShowLogin',0)
                this.isShowPop = false;
            },
        }
    }
</script>
<style lang="less" scoped>
    /*   登录  */
    .login_bg{
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.6);
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 111;
        .login_box{
            width: 490px;
            height: 340px;
            background: #fff;
            position: fixed;
            top: 50%;
            left: 50%;
            margin-left: -245px;
            margin-top: -170px;
            border-radius:10px;
            button{
                width:180px;
                height:60px;
                border:1px solid rgba(203,203,203,1);
                border-radius:4px;
                color: #CBCBCB;
                font-size: 20px;
                background: none;
                margin-top: 40px;
                margin-left: 49px;
                cursor: pointer;
                outline: none;
            }
            button.red{
                background: #E60026;
                color: #fff !important;
                margin-right: 49px;
                margin-left: 0;
                border:1px solid #E60026;
            }
            .showtip{
                height: 32px;
                line-height: 32px;
                padding: 0 20px;
                position: absolute;
                top: 50%;
                left:50%;
                transform: translate(-50%, -50%);
                background: rgba(0,0,0,.5);
                color: #fff;
                border-radius: 4px;
            }
            ul{
                width: 390px;
                margin: 0 auto;
                padding-top: 50px;
                li{
                    width: 388px;
                    height: 58px;
                    border: 1px solid #ddd;
                    border-radius: 4px;
                    margin-bottom: 20px;
                    float: left;
                    .btn{
                        float: left;
                        width: 58px;
                        height: 58px;
                        padding-right: 7px;
                        text-align: center;
                        line-height: 58px;
                    }
                    input{
                        width: 300px;
                        height: 58px;
                        float: left;
                        background: none;
                        font-size: 18px;
                    }
                }

            }
        }
    }

</style>
