<!-- toast 组件

调用方法   showToast('title')
-->
<template>
  <div>
     <div class="toast" v-show="toast">{{toastTitle}}</div>
  </div>
</template>
<script>
export default {
  props: {

  },
  data() {
    return {
      toast:false,
      toastTitle:''
    };
  },
  methods: {
     showToast(t){
      this.toast = true
      this.toastTitle = t
      setTimeout(()=>{
        this.toast = false
      },2000)
    }
  }
};
</script>
<style lang="scss" scoped>
.toast {
    height: 32px;
    line-height: 32px;
    padding: 0 20px;
    position: fixed;
    top: 50%;
    left:50%;
    transform: translate(-50%, -50%);
    background: rgba(0,0,0,.6);
    color: #fff;
    border-radius: 4px;
    z-index: 222;
  }
</style>
