import CryptJS from "crypto-js";
let UUID = require("uuid");
let NodeRSA = require("node-rsa");
class AESUtil {
    /**
     * AES 加密
     * @param _content 待加密内容
     * @param _key aesKey,
     * @param _iv 初始化向量
     * @return 返回经 BASE64 处理之后的密文
     */
    encrypt(_content, _key, _iv) {
            let content = CryptJS.enc.Utf8.parse(_content);
            let aesKey = CryptJS.enc.Utf8.parse(_key);
            let iv = CryptJS.enc.Utf8.parse(_iv);

            // 加密
            let encrypted = CryptJS.AES.encrypt(content, aesKey, {
                iv: iv,
                mode: CryptJS.mode.CBC,
                padding: CryptJS.pad.Pkcs7,
            });
            // console.log(encrypted)
            return CryptJS.enc.Base64.stringify(encrypted.ciphertext);
        }
        /**
         * AES 解密
         * @param：_content 待解密的内容[Base64处理过的]
         * @param：解密用的 AES key
         * @param: 初始化向量
         * @return 返回以 UTF-8 处理之后的明文
         */
    decrypt(_content, _key, _iv) {
        // let content = CryptJS.enc.Base64.parse(_content);
        // content = CryptJS.enc.Base64.stringify(content);
        let aesKey = CryptJS.enc.Utf8.parse(_key);
        let iv = CryptJS.enc.Utf8.parse(_iv);

        // 解密
        let decrypted = CryptJS.AES.decrypt(_content, aesKey, {
            iv: iv,
            mode: CryptJS.mode.CBC,
            padding: CryptJS.pad.Pkcs7,
        });
        // console.log(decrypted)
        return decrypted.toString(CryptJS.enc.Utf8);
    }
    getAesKey() {
        let uuid = UUID.v1();
        // console.log(uuid)
        let aeskey = CryptJS.enc.Utf8.parse(uuid);
        aeskey = CryptJS.enc.Base64.stringify(aeskey).substring(2, 34);
        // console.log(aeskey + "\n" + "长度：" + aeskey.length);
        return aeskey;
    }

    /**
     * 获得初始化向量
     * @returns 16 字节的初始化向量
     */
    getIv() {
        let uuid = UUID.v1();
        let iv = CryptJS.enc.Utf8.parse(uuid);
        iv = CryptJS.enc.Base64.stringify(iv).substring(2, 18);
        // console.log(iv + "\n" + "长度：" + iv.length);
        return iv;
    }

    /**
     * 获得 AES key 及 初始化向量 iv
     * 其实 iv 和 aesKey 两者的生成并没有什么关系，两者只是对各自的长度有限制，
     * 这里只是为了方便使用，进行了一个组合返回。
     * @return 返回 iv 和 aesKey 的组合
     */
    getAESKeyAndIv() {
        let aesKeyAndIv = {
            iv: this.getIv(),
            aesKey: this.getAesKey(),
        };

        return aesKeyAndIv;
    }
}

class RSAUtil {
    /**
     * 生成 RSA 密钥对
     * @returns 以 PEM 格式返回 公钥与私钥 的组合对象
     */
    getRSAKeyPair() {
        // 生成空对象
        let keyPair = new NodeRSA();
        keyPair.setOptions({
            encryptionScheme: "pkcs1",
        });
        // keyPairObj, 保存经 BASE64 编码处理之后 PEM 格式的 RSA 密钥对
        let keyPairObj = {
            publicKey: "",
            privateKey: "",
        };
        // keysize: 2048; 公指数为：65537
        keyPair.generateKeyPair(2048, 65537);
        /**
         * 导出密钥，对输出的密钥做一些格式化处理，以便 Java 端能直接使用，算然经过处理但是并不影响 JS 端的密钥导入，及正确性。
         * 1. 公钥
         * 2. 私钥
         */
        keyPairObj.publicKey = keyPair
            .exportKey("pkcs8-public-pem")
            .replace(/-----BEGIN PUBLIC KEY-----/, "")
            .replace(/-----END PUBLIC KEY-----/, "")
            .replace(/\n/g, "");
        keyPairObj.privateKey = keyPair
            .exportKey("pkcs8-private-pem")
            .replace(/-----BEGIN PRIVATE KEY-----/, "")
            .replace(/-----END PRIVATE KEY-----/, "")
            .replace(/\n/g, "");

        return keyPairObj;
    }

    /**
     * 公钥加密，加密之后以 BASE64 形式编码
     * @param buffer : 待加密内容 编码格式：utf-8
     * @param publicKey: 加密使用的公钥, 格式是：pkcs8 pem
     * @param encoding: 加密之后的输出编码类型 默认输出编码格式是：base64
     * @param source_encoding: 指定代加密内容的编码方式，默认是：utf-8
     * @returns: 返回以 BASE64 处理之后的加密内容
     */
    publicKeyEncrypt(
            buffer,
            pubicKey,
            encoding = "base64",
            source_encoding = "utf8"
        ) {
            // 导入 publickey
            let key = new NodeRSA();
            key.setOptions({
                encryptionScheme: "pkcs1", // 默认是：pkcs1_oaep，Java 端默认是 pkcs1, 这里做个修改
            });
            key.importKey(pubicKey, "pkcs8-public-pem");
            // 加密并返回加密结果
            return key.encrypt(buffer, encoding, source_encoding);
        }
        /**
         * 私钥解密，解密之后 返回 utf8编码的字符串
         * @param buffer: Buffer object or base64 encoded string
         * @param privateKey: 解密用的私钥，格式是：pkcs8 pem
         * @param encoding: 加密之后的类型 buffer OR json, 默认是 buffer
         * @returns：默认返回值类型就是 encoding 的默认值，即 buffer
         */
    privateKeyDecrypt(buffer, privateKey, encoding = "buffer") {
        // 导入 privatekey
        let key = new NodeRSA();
        key.setOptions({
            encryptionScheme: "pkcs1", // 默认是：pkcs1_oaep，Java 端默认是 pkcs1, 这里做个修改
        });
        key.importKey(privateKey, "pkcs8-private-pem");
        // 解密
        return key.decrypt(buffer, encoding);
    }
    pubicKeyDecrypt(buffer, pubicKey, encoding = "buffer") {
        // 导入 pubicKey
        let key = new NodeRSA();
        key.setOptions({
            encryptionScheme: "pkcs1", // 默认是：pkcs1_oaep，Java 端默认是 pkcs1, 这里做个修改
        });

        key.importKey(pubicKey, "pkcs8-public-pem");
        // 解密
        return key.decryptPublic(buffer, encoding);
    }
}

class encryptionandDecryption {
    constructor() {
        this.keyPair = {
            publicKey: "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAxytW7JXLU1LWlcFVpVFr5ogZs70PqGImmhqTxGRHvDXU4PmbYxNwAutGXH9w8v3OqAJv9lP8Gqd3YM46h3czb1yoTY8BUs93p1wkj+k4pocX8EAR+ks5r/VyI24kgV+sD7PEE4PknyY9xXMGQUtT3dfWWSsRh/U0Q4jCzPDARxQcQp6DWyv38bOZ35N74u0kszD1EpVj1rapwcNoGKWMGpK1DA3K/zDi24jDSAnT9TOFUiycrRDVzEGloZb6hVQ7UZWCv2bGvuzbEoQL0RLQ12ZOwd6t9aG9f+CIRfS4OvZFQHSFbp9XhrpkhnugQchC6yoQtUEMcLlAQiT4hxakLQIDAQAB",
            privateKey: "MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQDHK1bslctTUtaVwVWlUWvmiBmzvQ+oYiaaGpPEZEe8NdTg+ZtjE3AC60Zcf3Dy/c6oAm/2U/wap3dgzjqHdzNvXKhNjwFSz3enXCSP6TimhxfwQBH6Szmv9XIjbiSBX6wPs8QTg+SfJj3FcwZBS1Pd19ZZKxGH9TRDiMLM8MBHFBxCnoNbK/fxs5nfk3vi7SSzMPUSlWPWtqnBw2gYpYwakrUMDcr/MOLbiMNICdP1M4VSLJytENXMQaWhlvqFVDtRlYK/Zsa+7NsShAvREtDXZk7B3q31ob1/4IhF9Lg69kVAdIVun1eGumSGe6BByELrKhC1QQxwuUBCJPiHFqQtAgMBAAECggEAJdIgDosfcjIv135AIkWE5OEBaHVFbOc+h1L2rnuQd7gNNa+Qh07gnV1KHjSBIVN7E8Jqum8WV4VnkPV4fdLv7Hy+5QN779q/0QMZWk6iWFUdAZ1ZDJuOV9CXHQ1EV2DwjobHfCE2n9YmMsF19Z41TdI3vPUFvjic9RoaS8qH6Lcd4VPBa5FS9XeT3RUcyKjYm+k+D8knNMazIC/Omys4yN3XGqpiJ2RQM5Zt+JTaMZLsWx/Jol6k9XnwVNHpGIxWq6aOTXC5lfBsWSAECSuNK/pOJiJrnj2OBIyBp881iITdm38wLkEGSKnksjabCID9GiP0VYm9Tqu17I9IBIMIzQKBgQD0vnj2BlvNYXyp2F/oCeBhpg2pErr0sWqXRgD46/GiqiKY7cqu5icTC5b7nDs5ob+9OtoElq0B0oTbaePDCS5RTTAZj4uTKjQ9NzwSqPRkA7Qa/4XA9H/wpjXz+izX9VNbL20Osmi+MP5pkJ1QClW0WnUq9axw6BBgLj7g6la6+wKBgQDQVEle2xpKoxZY3MkbeVKC0IpLQiCEn20cRv9eTqsRSFAFO8APCkeX51c45BdymHbECakuKqqvyh5r6CDb+lbYzMTRYGwz1a7B58fQ0Goon/OSmcqKtHZRsUaProWhyM5rGQ5MwyQtYySDIo/aM4nkV3UuSlj/VWCEPtNCIsP09wKBgB/WCwwNxbFQphO95rPbXQm2vP4qRpF/5pqo64V1w6fiM7/q5rubiqeqkUhHiVi01qk515RTOUwMY2XbP1pmflTrEn+wsXpCOitgXPscaTX9WVdNt8tiwqW+KTSEbYLmEaLB55DK2l8rJlz7B8uanI1qWuVssbK6LS8ltN4y/XczAoGANWQkmabViiNZ6tRKxjvFpbkEeF0uME8bi4+2itjmhrtdprm1cyVTS98CP5ZVXA0vAvbm4OoWbI8EVOtc4IO8FjwEhRSNJouxCgj48M85Jcg4iG3ZtsqhODaSuugjX18zZ8c4Hvw0HV23UdRdwbJLuLWhVoe/X1DmN35SbjSyu+kCgYEAz3d82uQ9J2ZO99zzBSRybzUxx/CsnyCkcIbUAnOrQHCuIuIFq0sgtLRLN/5xM6ZAWWVZbMNpy/pAMr6vdHYsGdVN0qA/I1OYlsBUiHLpEvvKea7zywcyj4uWaaNZmz/+j2xSdojvQtFhHRh84MZVNO5bUyNd83SPgcDCMQuVjW8=",
        };
        this.keyAes = {
            iv: "0000000000000000",
            aesKey: "BjNzhiZDctOGMxOS",
            encrypted: "",
        };
    }
    solution(params) {
        let keyPair = this.keyPair;
        let encrypted1 = params.lock;
        let dencrypted1 = new RSAUtil().pubicKeyDecrypt(
            encrypted1,
            keyPair.publicKey
        );
        let keyAes = this.keyAes;
        keyAes.aesKey = dencrypted1.toString("utf8");
        keyAes.encrypted = params.data;
        let decrypted = new AESUtil().decrypt(
            keyAes.encrypted,
            keyAes.aesKey,
            keyAes.iv
        );
        return JSON.parse(decrypted);
    }
    plus(options) {
        let content = JSON.stringify(options);
        console.log(content);
        let keyAes = this.keyAes;
        let encrypted = new AESUtil().encrypt(content, keyAes.aesKey, keyAes.iv);
        let keyPair = this.keyPair;
        let lock = new RSAUtil().publicKeyEncrypt(keyAes.aesKey, keyPair.publicKey);
        return { lock: lock, data: encrypted, time: Date.parse(new Date()) };
    }
    privateKeyConte(params) {
        //		lock: E45ANJGokHdNWsvSuimpxmFQ3kn0HHYNuuuRH+xoovW3hRfF+YRjQFZN1ZCz35byK6IaskbespWJg2nGlYej7mDezfK6VKFP2zfKtsMZndO9GbZaTlp19Cg9UxKMqT1iHlLTM2DSfbLgCbrv5NQn9Pqy7d7qlGJp/388kYSnWuRdV1PE3pkWNS+h/N5um72932S5pmizzJf6YcemnFe9zd9F8mybgxU8D2tINnpABS2JAhc1pt4ucMAdUMcg9c351r94oMOPUkhG3loe+5f2y5ktY5Y1bvw3o6ArIHt2B4f8KectFnLxItu4ZWxNHXME3h6aS6eX8Gou8LCQ/LwJ3Q==
        //		data: MG9O05JO8Oj+LlEJpPj2aw==
        //		time: 1587200170000
        let keyPair = this.keyPair;

        let dencrypted = new RSAUtil().privateKeyDecrypt(
            params.lock,
            keyPair.privateKey
        );

        let keyAes = this.keyAes;
        keyAes.aesKey = dencrypted.toString("utf8");
        keyAes.encrypted = params.data;

        let decrypted = new AESUtil().decrypt(
            keyAes.encrypted,
            keyAes.aesKey,
            keyAes.iv
        );
        console.log(JSON.parse(decrypted));
        return JSON.parse(decrypted);
    }
}

//
export const solution = (params) =>
    new encryptionandDecryption().solution(params);
export const plus = (params) => new encryptionandDecryption().plus(params);

export const privateKeyConte = (params) =>
    new encryptionandDecryption().privateKeyConte(params);

export { AESUtil, RSAUtil };