import md5 from "md5"
import axios from "axios"
import qs from "qs"
import { AESUtil, RSAUtil, solution, plus } from "./encryption.js";
// 封装axios请求
const baseURL = process.env.VUE_APP_API_BASE_URL;
console.log(baseURL);
const service = axios.create({
    baseURL: "http://api.shareapi.daominyoupin.com:9530", // api base_url
    timeout: 6000, // 请求超时时间
});

// http://api.shareapi.daominyoupin.com:9530

const err = (error) => {
    console.log(error);
    if (error.response) {
        const data = error.response.data;
        const token = Vue.ls.get(ACCESS_TOKEN);
        if (error.response.status === 403) {
            alert(data.message);
        }
        if (
            error.response.status === 401 &&
            !(data.result && data.result.isLogin)
        ) {
            if (token) {
                window.location.reload();
            }
        }
    }
    return Promise.reject(error);
};

service.interceptors.request.use((config) => {
    config.headers["token"] = '9530';
    console.log(config);
    return config;
}, err);



service.interceptors.response.use((response) => {
    let responseData = response.data;
    if (response.data.lock) {
        responseData = solution(responseData);
    }
    return responseData;
}, err);

export { service as axios };


class Http {
    constructor(el) {
        this.el = el
            // this.Domain = 'http://tapi.daomingyl.com'; //测试接口

        this.Domain = 'https://zsapi.daomingyl.com/'; //正式接口
    }
    require(options) {
        if (!options.api) throw new Error('api 不能为空');
        if (!options.param) { options.param = {} }; //param==空  默认赋值一下
        if (!options.methods) { options.methods = 'POST' }; //不传递方法默认为POST
        let timer = Date.parse(new Date()) / 1000 //时间戳 以秒为单位
        let params = options.param ? options.param : {}
        params.t = timer
        params.device = 3;
        params.company = 1;
        params.token = md5(params.t + options.api.controller + options.api.action + 'Зашел в мире').toUpperCase();
        let cookie = JSON.parse(localStorage.getItem('cookie') || '{}')
        if (cookie.tokenId) {
            params.tokenId = cookie.tokenId;
        } else {
            params.tokenId = '';
            localStorage.setItem('cookie', JSON.stringify(cookie));
        }
        let pee = qs.stringify(params)
        return new Promise((resolve, reject) => {
            return axios({
                method: options.methods, //get post
                url: options.api.controller + '/' + options.api.action, // url
                baseURL: this.Domain, // 请求地址
                data: pee, //请求数据
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            }).then(response => {
                if (response.data.status == 200) {
                    resolve(response.data)
                } else {

                    resolve(response.data)
                }
            }, error => {
                resolve(error)
            })

        })

    }
}

export default Http;